// Import CSS for Vite. Path is relative to this JS file
import '@css/app.css'

//
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import persist from '@alpinejs/persist';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';
import morph from '@alpinejs/morph';
import ui from '@alpinejs/ui';
import splide from '@splidejs/splide';
import Headroom from "headroom.js";
//import Swiper from 'swiper/bundle';
//import Vimeo from '@vimeo/player';
//import 'swiper/css/bundle';
//import 'lite-youtube-embed';
//import marquee from './modules/marquee';

//import { gsap } from "gsap";
    
//import { ScrollTrigger } from "gsap/ScrollTrigger";


//gsap.registerPlugin(ScrollTrigger);

window.splide = splide;
window.Alpine = Alpine;
Alpine.plugin(ui);
Alpine.plugin(intersect);
Alpine.plugin(persist);
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(morph);

let header = document.querySelector(".js-header");
// construct an instance of Headroom, passing the element
if(header){
	var headroomHeader  = new Headroom(header, {
		offset : 0,
		tolerance : {
			up : 20,
			down : 0
		},
	});
	// initialise
	headroomHeader.init();
}

//window.ScrollTrigger = ScrollTrigger;

//window.Swiper = Swiper;

//window.Vimeo = Vimeo;

/*window.App = Object.assign(window.App !== undefined ? window.App : {}, {
	marquee: marquee
});*/


// .no-js to .js
let html = document.getElementsByTagName("html")[0];
html.className = html.className.replace("no-js", "js");

/*ScrollTrigger.create({
	start: 0,
	end: "max",
	onLeave: self => {
	  self.scroll(1);
	  ScrollTrigger.update();
	},
	onLeaveBack: self => {
	  self.scroll(ScrollTrigger.maxScroll(window)-1);
	  ScrollTrigger.update();
	}
});*/
  
  // Change opacity of link on scroll
  
  /*const select = (e) => document.querySelector(e);
  const selectAll = (e) => document.querySelectorAll(e);
  
  const website = selectAll(".website");
  
  website.forEach((website, i) => {
  
	gsap.to(website, {
		opacity: 1,
		repeat: 1,
		yoyo: true,
		ease: "none",
		scrollTrigger: {
		  trigger: website,
		  start: "center bottom",
		  end:"center top",
		  markers:false,
		  scrub:true,
		}
	  }
	);
  });*/


/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html & https://nystudio107.com/docs/vite/
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log('HMR')
	})
}

Alpine.start();